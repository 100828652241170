.evaluation-table {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.evaluation-table th,
.evaluation-table td {
  border: 1px solid #5eb562;
  text-align: left;
  padding: 8px;
}

.evaluation-table th {
  background-color: #5eb562;
}

.evaluation-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.evaluation-table tr:hover {
  background-color: #f2f2f2;
}

.evaluation-table h5 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.evaluation-table p {
  font-size: 1rem;
  margin-top: 10px;
}
