body {
  background: #eeeeee !important;
}

/** Phone Number input'ta ülke seçerken açılan combobox için */
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: 300px !important;
}

.custom-tooltip {
  width: 170px;
  margin: 0;
  line-height: 24px;
  border: 1px solid #f5f5f5;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
}

.custom-tooltip .label {
  margin: 0;
  color: #666;
  font-weight: 700;
}

.custom-tooltip .desc {
  margin: 0;
  color: #999;
}

.custom-tooltip .intro {
  border-top: 1px solid #f5f5f5;
  margin: 0;
}

.login-wrapper {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.singup-row {
  display: flex !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  border-color: #47a34b;
  background-color: #47a34b;
}

.rs-calendar-table-cell-in-range::before {
  background-color: #47a34b;
  opacity: 0.2;
  border-radius: 8px;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 0px;
}

.rs-calendar-table-cell-content:hover {
  background-color: #c1ecc2;
  color: "black";
}

.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled,
.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover,
.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover:active,
.rs-picker-toolbar-right-btn-ok {
  background-color: #23a12a;
}

.rs-picker-toolbar-right-btn-ok:active:hover,
.rs-picker-toolbar-right-btn-ok.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:hover,
.rs-picker-toolbar-right-btn-ok:active:focus,
.rs-picker-toolbar-right-btn-ok.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:focus,
.rs-picker-toolbar-right-btn-ok:active.focus,
.rs-picker-toolbar-right-btn-ok.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok.focus,
.rs-picker-toolbar-right-btn-ok:focus,
.rs-picker-toolbar-right-btn-ok.rs-btn-focus {
  color: #fff;
  background-color: #47a34b;
}

.rs-picker-toolbar-right-btn-ok:active,
.rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover {
  background-color: #47a34b;
}

.rs-picker-toolbar-option:active:hover,
.rs-picker-toolbar-option:active:focus,
.rs-picker-toolbar-option {
  color: black;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: #e5e5ea;
}

.linear-progress > span {
  height: 5px;
  width: 100%;
}

.modal-dialog .MuiPaper-root {
  overflow: hidden !important;
}

.signup-avatars-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

.second-avatar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.table-card-body {
  min-height: 404px !important;
}

.header-excel-icon {
  display: flex;
}

.header-excel-icon svg {
  height: 1.2em !important;
}
